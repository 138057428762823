import React, { useContext, useEffect, useState } from 'react'
import './Cartpurchaseform.css'
import CartContext from '../shopnow/context'
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';
import { service_id2, public_key2, template_id2 } from '../../../email_credientials';
import Successpopup from '../popup/Popup';
import { useNavigate } from 'react-router-dom';

const Cartpurchaseform = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phonenumber, setPhonenumber] = useState("")
    const [address, setAddress] = useState("")
    const addToCart = useContext(CartContext)
    const [addToCarts, setAddCarts] = useState([])
    const [isCart, setIsCart] = useState(false)
    const [showQr, setShowQr] = useState(false)
    const [totalPay, setTotalPay] = useState(0)
    const [isPhonenumber, setIsPhonenumber] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [mailStatus, setMailStatus] = useState("")
    const navigate = useNavigate()



    // const deleteItemCart = (index) => {
    //     const updatedCart = [...addToCart['addToCart']];
    //     updatedCart.splice(index, 1);
    //     console.log(index);
    //     console.log(updatedCart,'----------');
    //     setAddCarts(updatedCart);
    //     console.log(addToCarts);
    // }

    const submitFormDetail = (e) => {
        e.preventDefault();
        if (phonenumber.length !== 10) {
            setIsPhonenumber(true)
            setMailStatus("Failed")
            setIsPopupOpen(true);
            return
        } else {
            for (let i = 0; i < phonenumber.length; i++) {
                if (parseInt(phonenumber[i]) >= 0 && parseInt(phonenumber[i]) <= 9) { } else {
                    setIsPhonenumber(true)
                    setMailStatus("Failed")
                    setIsPopupOpen(true);
                    return
                }
            }
        }

        const cartItemsHtml = addToCarts.map((item, index) => `
            <tr>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;"><strong>Product ${index + 1} Name</strong></td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.name}</td>
            </tr>
            <tr style="background-color: #f9f9f9;">
                <td style="padding: 10px; border-bottom: 1px solid #ddd;"><strong>Size</strong></td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.size}</td>
            </tr>
            <tr>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;"><strong>Quantity</strong></td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.quantity}</td>
            </tr>
            <tr style="background-color: #f9f9f9;">
                <td style="padding: 10px; border-bottom: 1px solid #ddd;"><strong>Price</strong></td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.price}</td>
            </tr>
            <tr>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;"><strong>Total Price</strong></td>
                <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item.price * item.quantity}</td>
            </tr>
        `).join('');


        const templateParams = {
            name: name,
            email: email,
            mobile: phonenumber,
            address: address,
            product_details: cartItemsHtml,
            amount: totalPay
        };

        emailjs
            .send(service_id2, template_id2, templateParams, public_key2)
            .then(
                () => {
                    setMailStatus("Successfull")
                    setIsPopupOpen(true);
                    const timeoutId = setTimeout(() => {
                        window.location.reload();
                      }, 5000);
                    return () => clearTimeout(timeoutId); 
                },
                (error) => {
                    setMailStatus("Failed")
                    setIsPopupOpen(true);
                },
            );

    }
    const handleClose = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        if (Array.isArray(addToCart['addToCart'])) {
            setAddCarts(addToCart['addToCart']);
            const total = addToCarts.reduce((acc, item) => acc + (item.price * item.quantity), 0);
            setTotalPay(total);
        } else {
            setAddCarts([]);
        }
        AOS.init({
            duration: 2000,
            offset: 100,
        })
        if (addToCart['addToCart'].length <= 0) {
            navigate('/shop')
        } else {
            console.log(addToCart);
        }
    }, [addToCart]);

    return (
        <div className="cart_purchase_form">
            <div className='cart_purchase_div'>
                <h4 style={{ textAlign: "center", fontWeight: "700", color: "goldenrod" }}>PURCHASE FORM</h4>
                <form action='' className='w-100 flex flex-column justify-content-center align-items-center'>
                    <div className='form-group'>
                        <input className='form-control' type='text' placeholder='Enter your name' value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className='form-group'>
                        <input className='form-control' type='email' placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className='form-group'>
                        <input className='form-control' type='tel' placeholder='Enter your phone number' value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} required style={{ border: isPhonenumber ? "1px solid red" : "" }} />
                        {isPhonenumber ? <p style={{ color: "red" }}>Please enter valid phone number</p> : ""}
                    </div>
                    <div className='form-group'>
                        <textarea className='form-control' placeholder='Enter your address' value={address} onChange={(e) => setAddress(e.target.value)} required></textarea>
                    </div>
                    <div className='form-group'>
                        <button type='submit' className='btn btn-primary' onClick={submitFormDetail}>Submit</button>
                    </div>
                </form>
                {
                    isCart && addToCarts.length > 0 ?
                        <div style={{ backgroundColor: "#CCCCCC", borderRadius: "8px", padding: "10px" }} >
                            <div style={{ position: "relative" }}>
                                <h2 style={{ color: "grey", textAlign: "center", fontWeight: "700" }}>CART DETAILS</h2>
                                <i className="fas fa-times" style={{ position: "absolute", right: "0px", top: "0px", fontSize: "20px", cursor: "pointer" }} onClick={() => setIsCart(!isCart)}></i>
                            </div>
                            {
                                addToCarts && addToCarts.map((item, index) => (
                                    <div key={index} style={{ display: "flex", justifyContent: "space-around", alignItems: "center", margin: "5px", padding: "15px", backgroundColor: "#c8c4c4", borderRadius: "8px", position: "relative" }}>
                                        <div style={{ position: "relative" }}>
                                            <img src={item?.image} height="120px" width="120px" alt='error' />
                                        </div>
                                        <div>
                                            <h5 style={{ margin: 0, padding: 0 }}>{item?.name}</h5>
                                            <p style={{ margin: 0, padding: 0 }}>QTY: {item?.quantity}</p>
                                            <p style={{ margin: 0, padding: 0 }}>SIZE: {item?.size}</p>
                                            <p style={{ margin: 0, padding: 0 }}>PRICE: {item?.price}</p>
                                            <p style={{ margin: 0, padding: 0 }}>TOTAL PRICE: {item?.price * item?.quantity}</p>
                                        </div>
                                        {/* <i className='fas fa-trash' onClick={() => deleteItemCart(index)} style={{ fontSize: '25px', color: "black", marginRight: 8, position: "absolute", top: "0px", left: "0px", cursor: "pointer", backgroundColor: "#ff0000c9", padding: "5px", borderRadius: "4px" }}></i> */}
                                    </div>
                                ))
                            }
                        </div>
                        : ""
                }
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                    <button className='btn btn-warning ' style={{ width: "40%", fontWeight: "600" }} onClick={() => setShowQr(!showQr)}>Pay Online</button>
                    <button className='btn btn-warning' style={{ width: "40%", fontWeight: "600" }} onClick={() => setIsCart(!isCart)}>Cart</button>
                </div>
            </div>

            {
                showQr ?
                    <div className='payment_div' data-aos="fade-up">
                        <div style={{ border: "2px solid white", padding: "10px", backgroundColor: "#cccc", borderRadius: "8px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ position: "relative", width: "100%" }}>
                                <i className="fas fa-times" style={{ position: "absolute", right: "0px", top: "0px", fontSize: "20px", cursor: "pointer" }} onClick={() => setShowQr(!showQr)}></i>
                            </div>

                            <img src={`${process.env.PUBLIC_URL}/images/Qr_payment.jpeg`} alt='error' style={{ height: "50%", width: "100%", borderRadius: "8px" }}></img>
                            {
                                addToCarts.length > 0 ?
                                    <div style={{ borderRadius: "8px", padding: "5px", margin: "5px", width: "80%" }} >
                                        {
                                            addToCarts && addToCarts.map((item, index) => (
                                                <div key={index} style={{}}>
                                                    <h6 style={{ margin: 0, padding: 0 }}>{item?.name}</h6>
                                                    <p style={{ margin: 0, padding: 0 }}>PRICE: {item?.quantity * item?.price}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : ""
                            }
                            <div style={{ border: "1px solid black", width: "100%" }}></div>
                            <h6 style={{ textAlign: "left" }}>Total Price: {totalPay}<br /></h6>
                        </div>
                    </div> :
                    ""
            }
            {
                addToCarts.length <= 0 ?
                    <h5 style={{ color: "red" }}>*You have no cart data move back to shop now.</h5> :
                    ""
            }

            {isPopupOpen === true && (
                <Successpopup onClose={handleClose} title={mailStatus} />
            )}
        </div>

    )
}

export default Cartpurchaseform