import React, { useState, useEffect } from 'react'
import './Footer.css'
const Footer = () => {
    const [year, setYear] = useState(null);
    useEffect(() => {
        const today = new Date();
        const currentYear = today.getFullYear();
        setYear(currentYear)
    }, [])
    return (
        <>
            <footer className='d-flex justify-content-center' id="footerPages">
                <div className='p-3 ' style={{ width: "95%" }}>
                    <hr />
                    <div className='footer_set_1'>
                        <div className='show_address' style={{ display: "flex" }}>
                            <i className='fas fa-map-marker-alt' style={{ fontSize: '25px', color: "black", marginRight: 8 }}></i>
                            <p className='text-start footer_text' id="footer_content">SHREE PREMANAND FOOD PRIVATE LIMITED <br />
                                F-326 , GROUND FLOOR, HARSH VIHAR , HARI <br />
                                NAGAR PART III JAITPUR, South East, <br />
                                Delhi-110044</p>
                        </div>
                        <div className='show_contect_details' >
                            <p className='text-start footer_text' id="footer_content">For Sales and Trade Inquire - <br />&nbsp;&nbsp;
                                <i className='fas fa-phone' style={{ fontSize: '20px', color: "black", marginRight: 3 }}></i> +91 98914 66645 <br />&nbsp;&nbsp;
                                <i className='fas fa-envelope' style={{ fontSize: '20px', color: "black", marginRight: 3 }}></i>    info@premanandfood.com </p>
                        </div>
                        <div className='show_contect_details d-grid justify-content-center gap-1'>
                            <p className='text-center footer_text' id="footer_content" style={{ margin: "0px" }}>CERTIFIED BY:</p>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2, margin: "5px 0px" }}>
                                <img src="./images/fssai_img.png" alt="fssai" width={60}></img>
                                {/* <p className='text-center footer_text' id="footer_content" style={{ margin: "0px" }}>LIC NO: 13324010000113</p> */}
                                <p className='text-center footer_text' id="footer_content" style={{ margin: "0px" }}>CIN: U10504DL2023PTC423542</p>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2, margin: "5px 0px" }}>
                                 <img src="./images/start_up_india.png" alt="fssai"  height={30} ></img>
                                <p className='text-center footer_text' id="footer_content" style={{ margin: "0px" }}>LIC NO: DIPP153556</p>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2, margin: "5px 0px" }}>
                                <img src="./images/msme.png" alt="fssai" width={60}></img>
                                <p className='text-center footer_text' id="footer_content" style={{ margin: "0px" }}>LIC NO: UDYAM-DL-08-0065338</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='footer_second_part_main' >
                        <div className='footer_second_part'>
                            {/* <img src="./images/premanand_logo_footer.png" alt="not found" width={"40%"} height={"40%"} /> */}
                            <p className='text-center pt-2 footer_text ' id="footer_content">Disclaimer: Premanand Food is an independent entity and is not associated with Premanand Maharaj.
                                We are dedicated to providing quality food products and experiences,
                                distinct from any religious or spiritual affiliation. #PremAnandFood</p>
                            <div className='d-flex justify-content-center m-0'>
                                <a href="https://www.facebook.com/premanandfood" target='blank' className='p-2'><i id="social_media" className='fab fa-facebook' style={{ fontSize: '25px', color: '#1877F2' }}></i></a>
                                <a href="https://www.instagram.com/premanandfoods?igsh=MXp6d3NwdWV1N3Nj" target='blank' className='p-2'><i id="social_media" className='fab fa-instagram' style={{ fontSize: '25px', color: "#C13584" }}></i></a>
                                <a href="" className='p-2'><i id="social_media" className='fab fa-linkedin' target='blank' style={{ fontSize: '25px', color: "#0077B5" }}></i></a>
                            </div>
                        </div>
                        <div className='d-grid justify-content-center'>

                            <h4 className="service_head" id="footer_content">Our Service</h4>
                            <a href="" className="service_head_anchore" id="footer_content">Our Products</a>
                            <a href="about" className="service_head_anchore" id="footer_content">About Premanand Food</a>
                            <a href="blog" className="service_head_anchore" id="footer_content">Blog</a>
                            <a href="shop" className="service_head_anchore" id="footer_content">Shop Now</a>
                            <a href="contact" className="service_head_anchore" id="footer_content">Enquiry</a>
                        </div>

                    </div>
                    <hr />
                    <div className='imp_notice'>
                        <div className=' footer_right_part'>
                            <span ><a href="policy-policy" className="service_head_anchore" id="footer_content">Privacy Policy</a>  |  <a href="terms-and-conditions" className="service_head_anchore" id="footer_content">Terms and Conditions</a></span>
                        </div>
                        <p className='text-center service_head_anchore'>Copyright <i className='fa fa-copyright'></i>  premanandfood.com {year}</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer