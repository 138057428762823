import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './popup.css'

const Successpopup = ({ onClose, title }) => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of the animation in milliseconds
            once: true, // Whether animation should happen only once
        });
    }, []);
    return (
        <>
            <div className='overlayStyle'>
                <div className='popupStyle' data-aos="fade-in">
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        {
                            title === "Successfull" ?
                                <>

                                    <p data-aos="fade-in" style={{ fontSize: "30px", border: "5px solid green", borderRadius: "50%", color: 'white', backgroundColor: "green", padding: "5px" }}> <i className='fas fa-check' style={{ fontSize: '50px', color: 'white', }}></i></p>
                                    <h2>{title}!</h2>
                                    <p className='text-center'>Your mail send premanandfood successfully.</p>
                                    <button className='btn btn-primary px-3' onClick={onClose}>OK</button>
                                </>
                                :
                                <>
                                    <p data-aos="fade-in" style={{ fontSize: "30px", border: "5px solid red", borderRadius: "50%", color: 'white', backgroundColor: "red", padding: "5px" }}> <i className='fas fa-times' style={{ fontSize: '50px', color: 'white', }}></i></p>
                                    <h2>{title}!</h2>
                                    <p>Something went wrong.</p>
                                    <button className='btn btn-primary px-3' onClick={onClose}>OK</button>
                                </>

                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default Successpopup