import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './component/pages/home/Home';
import Contact from './component/pages/contact/Contact';
import Blog from './component/pages/blog/Blog';
import About from './component/pages/about/About'; // Corrected import path
import Header from './component/common/header/Header';
import Footer from './component/common/footer/Footer';
import Privacyandpolicy from './component/policy and rules/privacy_and_policy/Privacyandpolicy';
import Termsandconditions from './component/policy and rules/terms_and_conditions/Termsandconditions';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WhatsAppIcon from './component/common/whatsapp/Whatsapp';
import Shopnow from './component/pages/shopnow/Shopnow';
import Cartpurchaseform from './component/pages/cartpurchaseform/Cartpurchaseform';
// import { CartContext } from './component/pages/shopnow/Shopnow';
import CartContext from './component/pages/shopnow/context';

function App() {
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [addToCart, setAddToCart] = useState([])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);
  return (
    <>
      <CartContext.Provider value={{addToCart, setAddToCart}}>
        <BrowserRouter>
          <Header isVisible={isVisible} />
          <Routes>
            <Route path='' element={<Home />} />
            <Route path='contact/' element={<Contact />} />
            <Route path='about/' element={<About />} />
            <Route path='blog/' element={<Blog />} />
            <Route path='shop/' element={<Shopnow />} />
            <Route path='product-form/' element={<Cartpurchaseform/>} />
            <Route path='policy-policy/' element={<Privacyandpolicy />} />
            <Route path='terms-and-conditions/' element={<Termsandconditions />} />
          </Routes>
          <Footer />
        </BrowserRouter>
        <WhatsAppIcon />
      </CartContext.Provider>
    </>
  );
}

export default App;
