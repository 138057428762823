import React from 'react'
import './About.css'
import ReactPlayer from 'react-player';
const About = () => {
    return (
        <>
            <div className='container-fluid p-0'>
                <div className='d-flex flex-column justify-content-center align-items-center typing_header border-success py-3'>
                    <h1 className='text-center' style={{ paddingTop: "10vh" }}>Welcome to Premanand – Where Tradition Meets Purity!.</h1>
                    <div className='vedio_write_container'>
                        <div className='hindi_container'>
                            <p className='text-center px-2' id='details_aboutus'>ABOUT, प्रेमानंद शुद्ध देशी
                                हमारा सिर्फ एक ही लक्ष्य है  कि आप सब को शुद्ध घी मिल पाए।  इस लक्ष्य को पूरा करने के लिए हम ग्रामीण किसानो से उच्य श्रेड़ी का दूघ खरीदते है , तत्पश्चात उस दूध की मानक जांच कर सही मानक वाले दूध को लकड़ी और कंडे / उपले की आग पर 5 से 6 घंटे के लिए एक निर्धारित तापमान पर मिटटी  के बर्तन में गर्म किया जाता है।
                                दूध को गर्म करने की प्रक्रिया पूर्ण होने के बाद एक नार्मल ताप पर दूध से  दही बनाने की विधि को पूर्ण कर 8 -10 घंटे के लिए रख दिया जाता है।
                                इस तरह शुद्ध दही बनकर तैयार हो जाता है। बिलोना विधि का प्रयोग करके ब्रम्ह मुहर्त में दही से माखन निकालने की प्रकिर्या पूर्ण की जाती है ।
                                दही से निकले हुए  माखन को पुनः लकड़ी और कंडे / उपले की धीमी धीमी आग पर 4 से 5 घंटे के लिए पकाया जाता है। और इस तरह आपका प्रेमानंद शुद्ध देशी घी  बनकर तैयार हो जाता है ।</p>
                        </div>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={`${process.env.PUBLIC_URL}/videos/premandvedio.mp4`}
                                width='100%'
                                height='100%'
                                // controls
                                playing={true}
                                muted={true}
                                loop={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='my-4'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex justify-content-center'>
                            <div className='founder_desc'>
                                <p className='text-left px-2' id='details_aboutus'>At Premanand, we believe in the timeless essence of purity and tradition. Our journey is rooted in the rich heritage of India's culinary traditions, where every product reflects a deep connection to our cultural roots.</p>
                                <p className='px-2 ' style={{ textAlign: "left" }} id='details_aboutus'>Founded with a passion for delivering the finest quality food products, Premanand is committed to sourcing the best ingredients and employing traditional techniques to craft products that embody authenticity and taste.
                                    As opposed to the norms, the brand avoids extensive clarification and delivers products intact with their nutritive quotient, while not compromising its thoughtful packaging standards.
                                </p>
                                <p className='px-2 ' style={{ textAlign: "left" }} id='details_aboutus'>
                                    We take pride in our meticulous approach to quality assurance, ensuring that each product that bears the Premanand name meets the highest standards of purity and excellence. From our kitchen to your table, we strive to deliver an experience that delights the senses and nourishes the soul.
                                </p>
                                <p className='px-2 ' style={{ textAlign: "left" }} id='details_aboutus'>
                                    Whether it's our signature Sudh Desi Ghee, artisanal sweets, or wholesome snacks, every bite of Premanand is a celebration of tradition, flavor, and goodness.
                                </p>
                                <p className='px-2 ' style={{ textAlign: "left" }} id='details_aboutus'>
                                    Join us on this journey as we continue to uphold the legacy of purity and authenticity that defines Premanand.
                                </p>
                                <br />
                                <br />
                                <p className='px-4 ' style={{ textAlign: "end" }} id='details_aboutus'>Experience the difference with Premanand – Where Tradition Meets Purity!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About