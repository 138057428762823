import React from 'react'
import BannerComponent from './banner/Banner'
import ShowProduct from './product_temp/ShowProduct'
import Strenght from './our_strength/Strenght'
import OurProduct from './our_best_product/OurProduct'
import Vedio from './vedio/Vedio'
const Home = () => {
  return (
    <>
        <div style={{backgroundColor:"#FAE3B9", position:"relative"}}>
            <BannerComponent/>
            {/* <ShowProduct/> */}
            {/* <Strenght/> */}
            {/* <OurProduct/>
            <Vedio/> */}
        </div>
    
    </>
  )
}

export default Home