import { React, useEffect, useState } from 'react'
import emailjs from '@emailjs/browser';
import './Contact.css'
import { service_id, template_id, public_key } from '../../../email_credientials';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Successpopup from '../popup/Popup';

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [desc, setdesc] = useState("");
    const [validate, setValidate] = useState(false);
    const [emailValidate, setEmailValidate] = useState(false);
    const [wrongEmailValidate, setWrongEmailValidate] = useState(false);
    const [phoneValidate, setPhoneValidate] = useState(false);
    const [wrongPhoneValidate, setWrongPhoneValidate] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [mailStatus, setMailStatus] = useState("")

    const verifyEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const letTalks = (e) => {
        e.preventDefault();
        const templateResponse = {
            "to_name": "Premanand",
            "username": name,
            "useremail": email,
            "userphone": mobile,
            "usermessage": desc
        }
        if (email && mobile) {
            if (verifyEmail(email)) {
                setEmailValidate(true)
                setWrongEmailValidate(false)
            }

            if (mobile.length === 10) {
                for (let i = 0; i < mobile.length; i++) {
                    if (parseInt(mobile[i]) >= 0 && parseInt(mobile[i]) <= 9) {
                    } else {
                        setMailStatus("Failed")
                        setIsPopupOpen(true);
                        return
                    }
                }
            } else {
                setMailStatus("Failed")
                setIsPopupOpen(true);
                return
            }
        }

        if (email && mobile && desc && name) {
            emailjs
                .send(service_id, template_id, templateResponse, public_key)
                .then(
                    () => {
                        setMailStatus("Successfull")
                        setIsPopupOpen(true);
                        setName("");
                        setEmail("");
                        setMobile("");
                        setdesc("");
                    },
                    (error) => {
                        setMailStatus("Failed")
                        setIsPopupOpen(true);
                    },
                );
        } else {
            setIsPopupOpen(true);
            setMailStatus("Failed")
        }
    }

    const handleClose = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => { }, [emailValidate, phoneValidate]);

    return (
        <>
            <div className='contact_page container-fluid d-flex justify-content-center rounded pt-7 pb-5' style={{ padding: 0, backgroundColor: "#fff", paddingTop: "15vh", }}>
                <div className=' py-2' id="contactcontainer">
                    <div className='contact_sub_page p-2'>
                        <form onSubmit={letTalks}>
                            <div className='w-100 d-flex justify-content-center my-3'>
                                <label htmlFor=""></label>
                                <input className='input_contact' type='text' placeholder='Enter Your Name' value={name} onChange={(e) => setName(e.target.value)} required />
                            </div>

                            <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                                <label htmlFor=""></label>
                                <input className='input_contact text-black' type="email" placeholder='Enter Your Mail Id' required value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='w-100 d-flex flex-column justify-content-center align-items-center my-3'>
                                <label htmlFor=""></label>
                                <input className='input_contact text-black' type="tel" placeholder='Enter Your Mobile Number' required value={mobile} onChange={(e) => setMobile(e.target.value)} />
                            </div>
                            <div className='w-100 d-flex justify-content-center my-3'>
                                <label htmlFor=""></label>
                                <textarea className='input_contact' placeholder='Enter Your Message' value={desc} onChange={(e) => setdesc(e.target.value)} required></textarea>
                            </div>
                            <div className='w-100 d-flex justify-content-center my-3'>
                                <button className='' id="chat_button" type="submit">{"Let's chat"}</button>
                            </div>
                        </form>
                    </div>
                </div>
                {isPopupOpen === true && (
                    <Successpopup onClose={handleClose} title={mailStatus} />
                )}
            </div>
        </>
    )
}

export default Contact;
