import {useEffect} from 'react'
import './Strength.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Strenght = () => {
    useEffect(() => {
        AOS.init({duration:2000,
        offset:100,
        })
      }, []);
    return (
        <>
            <div className='container-fluid p-0'  >
                <div className='strength_body' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/strength_bg.png)` }} >
                    <div className='d-flex flex-direction-column justify-content-center'>
                        <h1 data-aos="fade-up"  className=' aos-init aos-animate text-center strength_heading' >Why Shree Premanand?<br />
                            comes from the best of Nature</h1>
                    </div>
                    <div data-aos="zoom-in" className='d-flex flex-wrap justify-content-around mt-5'>
                        <div>
                            <img src="" alt="" />
                            <p id="stregth_pr" className='text-center'>100% Natural <br /> ingredients</p>
                        </div>
                        <div>
                            <img src="" alt="" />
                            <p id="stregth_pr" className='text-center'>Ethically & <br /> Sustainably sourced</p>
                        </div>
                        <div>
                            <img src="" alt="" />
                            <p id="stregth_pr" className='text-center'>Preservative Free</p>
                        </div>
                        <div>
                            <img src="" alt="" />
                            <p id="stregth_pr" className='text-center'>Unprocessed Products</p>
                        </div>
                        <div>
                            <img src="" alt="" />
                            <p id="stregth_pr" className='text-center'>100% Natural <br /> ingredients</p>
                        </div>
                    </div>
                    <div data-aos="fade-in" className='d-flex justify-content-center mt-5'>
                        <p id="stregth_pr" className='text-center'>At Shree Premanand we have no secrets, that is our real secret. We truly believe in creating everything<br/>
                            pure and natural. Want to know how?</p>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Strenght
