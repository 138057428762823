export const productDetails = [
    {
        "image":"https://www.servio-usa.com/themes/vox/assets/images/ghee-transparente2.png",
        "product":"Desi Ghee",
        "short_desc":"Our A2 Gir Cow Ghee is made from the A2 milk of Gir cows, a purebred (non-hybrid) desi cattle breed from the Gujarat and Rajasthan regions of India. ",
        "rating":5
    },
    {
        "image":"http://vijaymilk.com/wp-content/uploads/2018/09/ghee1.png",
        "product":"Desi Ghee",
        "short_desc":"Our A2 Gir Cow Ghee is made from the A2 milk of Gir cows, a purebred (non-hybrid) desi cattle breed from the Gujarat and Rajasthan regions of India. ",
        "rating":5
    },
    {
        "image":"https://www.grbdairyfood.com/image/products/ghee-products/ghee1.png",
        "product":"Desi Ghee",
        "short_desc":"Our A2 Gir Cow Ghee is made from the A2 milk of Gir cows, a purebred (non-hybrid) desi cattle breed from the Gujarat and Rajasthan regions of India. ",
        "rating":5
    },
    {
        "image":"https://static.wixstatic.com/media/f0fada_8bf07836b03c4151879fa6d779833d5c~mv2.png/v1/crop/x_148,y_0,w_932,h_832/fill/w_519,h_462,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/10_edited.png",
        "product":"Desi Ghee",
        "short_desc":"Our A2 Gir Cow Ghee is made from the A2 milk of Gir cows, a purebred (non-hybrid) desi cattle breed from the Gujarat and Rajasthan regions of India. ",
        "rating":4
    },
    {
        "image":"https://www.grbdairyfood.com/image/products/ghee-products/ghee1.png",
        "product":"Desi Ghee",
        "short_desc":"Our A2 Gir Cow Ghee is made from the A2 milk of Gir cows, a purebred (non-hybrid) desi cattle breed from the Gujarat and Rajasthan regions of India. ",
        "rating":4
    }
]

export const productData = [
    {
        "id":1,
        "name": "Premanand Baffalo Ghee",
        "image": `${process.env.PUBLIC_URL}/images/products/3-removebg-preview.png`,
        "size": [
            "size ",
            250,
            500,
        ],
        "price": [
            " price",
            500, 
            1000
        ]
    },
    // {
    //     "name": "Premanand Mustard Oil",
    //     "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Mustard_Oil_%26_Seeds_-_Kolkata_2003-10-31_00537.JPG/1280px-Mustard_Oil_%26_Seeds_-_Kolkata_2003-10-31_00537.JPG",
    //     "quantity": [
    //         250,
    //         500,
    //     ],
    //     "price": [
    //         500, 
    //         1000
    //     ]
    // }
]