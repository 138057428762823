import React, { useState, useEffect } from 'react';
import './Banner.css'
import ReactPlayer from 'react-player';

const BannerComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
  
    './images/banner/Premanand_Banner3.png',
    './images/banner/banner1.jpeg',
    './images/banner/banner2.jpeg',
    './images/banner/banner3.jpeg',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <div className="banner-container">
        <div className="banner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((src, index) => (
            <div className="d-block w-100 res-banner">
              <img key={index} src={src} className="d-block w-100 res-banner" alt="banner" />
            </div>
          ))}
        </div>
        <div className="dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            >
              •
            </span>
          ))}
        </div>
      </div>
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={`${process.env.PUBLIC_URL}/videos/premandvedio.mp4`}
          width='100%'
          height='100%'
          // controls
          playing={true}
          muted={true}
          loop={true}
        />
      </div>
    </>
  );
};

export default BannerComponent;

