import React, { useState, useEffect } from 'react'

const Termsandconditions = () => {
    const [date, setDate] = useState(null);
    useEffect(() => {
        const today = new Date();
        setDate(today.toLocaleDateString());
    }, [])
    return (
        <>
            <div className='container' style={{ paddingTop: "12vh" }}>
                <div className='d-flex justify-content-center'>
                    <h2 style={{ color: "black", fontFamily: "fantasy", textDecorationLine: "underline", fontWeight: 400 }}>TERMS AND CONDITIONS</h2>
                </div>
                <br />
                <div className='d-grid justify-content-center'>
                    <p className='text-start' style={{fontSize:"18px"}}>Please read these Terms and Conditions ("Terms") carefully before using the Premanand website (the "Service") operated by PremAnand ("us", "we", or "our").</p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Agreement to Terms:</h5>
                    <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Use of the Service:</h5>
                    <p style={{fontSize:"18px"}}>
                        You must be at least 18 years old to use the Service.<br />
                        You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device.<br />
                        You agree to provide accurate, current, and complete information about yourself when using the Service.,<br />
                        You agree not to use the Service for any illegal or unauthorized purpose.
                    </p>
                    <br />
                    <h5 style={{ color: "black",  fontWeight: 700 }}>Intellectual Property:</h5>
                    <p style={{fontSize:"18px"}}>
                        The Service and its original content, features, and functionality are owned by Premanand and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Disclaimer:</h5>
                    <p style={{fontSize:"18px"}}>
                        The Service is provided on an "as-is" and "as-available" basis without any representations or warranties, express or implied.<br />
                        We do not warrant that the Service will be error-free or uninterrupted, or that defects will be corrected.
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Limitation of Liability:</h5>
                    <p style={{fontSize:"18px"}}>
                        In no event shall Premanand, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Indemnification:</h5>
                    <p style={{fontSize:"18px"}}>
                        You agree to indemnify and hold Premanand and its affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms or your violation of any law or the rights of a third party.
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Governing Law:</h5>
                    <p style={{fontSize:"18px"}}>
                        These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Changes:</h5>
                    <p style={{fontSize:"18px"}}>
                        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Contact Us:</h5>
                    <p style={{fontSize:"18px"}}>
                        If you have any questions about these Terms, please contact us at <b>info@premanandfood.com</b>.
                        <br />
                        Last Updated: <b>{date}</b>
                    </p>
                    <br />
                    <p style={{fontSize:"18px"}}>This Terms and Conditions page was last updated on the date indicated above.</p>










                </div>

            </div>
        </>
    )
}

export default Termsandconditions