import React,{useState, useEffect} from 'react'


const Privacyandpolicy = () => {
    const [date, setDate] = useState(null);
    useEffect(()=>{
        const today = new Date();
        setDate(today.toLocaleDateString());
    },[])
    return (
        <>
            <div className='container' style={{ paddingTop: "12vh" }}>
                <div className='d-flex justify-content-center'>
                    <h2 style={{ color: "black", fontFamily: "fantasy", textDecorationLine: "underline", fontWeight: 400 }}>PRIVACY POLICY</h2>
                </div>
                <div className='d-grid justify-content-center'>
                    <p className='text-left' style={{fontSize:"18px"}}>
                        At Premanand, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you visit our website or interact with us.
                    </p>
                    <h5 style={{ color: "black", fontWeight: 700 }}>Information We Collect:</h5>
                    <p style={{fontSize:"18px"}}>
                        <b>Personal Information:</b> When you visit our website, place an order, or communicate with us, we may collect personal information such as your name, email address, phone number, and shipping address.
                        <br />
                        <br />
                        <b>Payment Information:</b> If you make a purchase on our website, we may collect payment information such as credit card details or other payment details. However, please note that we do not store any payment information on our servers. All payment transactions are processed securely through third-party payment processors.
                        <br />
                        <br />
                        <b>Other Information:</b> We may also collect non-personal information such as your IP address, browser type, and device information when you visit our website.
                        <br />
                    </p>
                    <p style={{fontSize:"18px"}}>
                        We use the information we collect to process your orders, communicate with you about your orders, and provide customer support.<br />
                        We may also use your information to personalize your experience on our website and improve our products and services.<br />
                        We may send you promotional emails about new products, special offers, or other updates if you have opted in to receive such communications. You can opt out of these emails at any time by clicking the unsubscribe link at the bottom of the email.<br />
                        We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or as necessary to fulfill your orders.<br />
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Information We Collect:</h5>
                    <p style={{fontSize:"18px"}}>We implement a variety of security measures to protect your personal information, including encryption, firewalls, and secure socket layer technology (SSL).
                        We restrict access to your personal information to authorized employees who need to know that information in order to process your orders or provide customer support.</p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Cookies:</h5>
                    <p style={{fontSize:"18px"}}>
                        We may use cookies and similar tracking technologies to collect information about your browsing behavior on our website. You can choose to accept or decline cookies through your browser settings. However, please note that disabling cookies may affect your experience on our website.
                    </p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Third-Party Links:</h5>
                    <p style={{fontSize:"18px"}}>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party websites or services. We recommend reviewing the privacy policies of these websites or services before providing any personal information.</p>
                    <br />
                    <h5 style={{ color: "black",  fontWeight: 700 }}>Children's Privacy:</h5>
                    <p style={{fontSize:"18px"}}>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you believe that we have inadvertently collected personal information from a child under the age of 13, please contact us immediately so that we can take appropriate action.</p>
                    <br/>
                    <h5  style={{ color: "black", fontWeight: 700 }}>Changes to this Privacy Policy</h5>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes or updates will be posted on this page, and the "Last Updated" date at the top of this page will be revised accordingly. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
                    <br />
                    <h5 style={{ color: "black", fontWeight: 700 }}>Contact Us</h5>
                    <p style={{fontSize:"18px"}}>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <b>info@premanandfood.com</b>.
                    <br />
                    Last Updated: <b>{date}</b>
                    </p>
                    <br />
                    <p style={{fontSize:"18px"}}>This Privacy Policy was last updated on the date indicated above.</p>
                </div>
            </div>
        </>
    )
}

export default Privacyandpolicy