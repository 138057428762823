import React, { useState, useEffect, useContext } from 'react'
import { productData } from '../../../static_data'
import './Shopnow.css'
import { useNavigate } from 'react-router-dom';
import CartContext from './context';

const Shopnow = () => {
  const { addToCart, setAddToCart } = useContext(CartContext)
  const [quantity, setQuantity] = useState(0)
  const [price, setPrice] = useState(0)
  const [productSize, setProductSize] = useState(0)
  const navigate = useNavigate()


  const handleSelectChange = (e) => {
    const selectedValue = e.target.value.split(',');
    const size = parseInt(selectedValue[0], 10);
    const price = parseInt(selectedValue[1], 10);
    setPrice(price);
    setProductSize(size);
    setQuantity(0);
  };


  const add_to_cart = (data) => {
    let check_is_quant = false
    const cart_value = {
      id: data.id,
      name: data.name,
      image: data.image,
      quantity,
      "price": price,
      size: productSize
    };
    if (addToCart.length > 0) {
      for (let i = 0; i < addToCart.length; i++) {
        if (productSize === addToCart[i].size && addToCart[i].id === data.id) {
          addToCart[i].quantity = (addToCart[i].quantity - addToCart[i].quantity) + quantity
          console.log(addToCart);
          check_is_quant = true
          break
        }
      }
      if (check_is_quant === true) {
        return
      }
    }
    if (quantity > 0) {
      setAddToCart(prevCart => [...prevCart, cart_value]);
    }
    setQuantity(0);
  };

  const deleteItemCart = (index) => {
    const updatedCart = [...addToCart];
    updatedCart.splice(index, 1);
    setAddToCart(updatedCart);
  }

  useEffect(() => {
  }, [addToCart]);
  return (
    <>
      <CartContext.Provider value={{ addToCart }}>
        <div style={{ paddingTop: "90px" }}>
          <div style={{ display: "flex", gap: "5px", justifyContent: "space-around", flexWrap: "wrap", marginTop: "2%", marginBottom: "5%" }}>
            {
              productData.map((item, index) => (
                <div key={index} className='product_cont'>
                  <img src={item.image} alt='product image' style={{ width: "100%", height: "auto", display: "flex", justifyContent: "center", alignItems: "center", }} />
                  <h5 className='text-center py-2'>{item?.name}</h5>
                  <div style={{ display: "flex", justifyContent: "space-around", width: "100%", }}>
                    <div style={{ width: "60%", height: "100%" }}>
                      <select id={`quantity-${index}`} style={{ display: "flex", width: "100%", padding: "5px", }} onChange={handleSelectChange}>
                        {item.size.map((size, sizeIndex) => (
                          <option key={sizeIndex} value={[size, item['price'][sizeIndex]]}>{size} ML-₹{item['price'][sizeIndex]}</option>
                        ))}
                      </select>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "30%", alignItems: "center", height: "100%" }}>
                      <i className='fas fa-plus' style={{ fontSize: '20px', color: "black", borderRadius: '4px', padding: 5, backgroundColor: "goldenrod", cursor: "pointer" }} onClick={() => quantity >= 0 ? setQuantity(quantity + 1) : setQuantity(0)}></i>
                      <p style={{ fontSize: '25px', color: "black", margin: 0, }}>{quantity}</p>
                      <i className='fas fa-minus' style={{ fontSize: '20px', color: "black", borderRadius: '4px', padding: 5, backgroundColor: "goldenrod", cursor: "pointer" }} onClick={() => quantity > 0 ? setQuantity(quantity - 1) : setQuantity(0)}></i>
                    </div>
                  </div>
                  <div style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    backgroundColor: "goldenrod",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4px",
                    cursor: "pointer"
                  }} onClick={() => add_to_cart(item)}>
                    <p style={{
                      padding: "4px 8px",
                      margin: 0,
                      fontSize: "16px",
                      fontWeight: "bold"
                    }}>
                      ADD TO CART
                    </p>
                  </div>
                </div>
              ))
            }
          </div>

          {
            addToCart.length > 0 ?
              <div style={{ display: "flex", justifyContent: "center", paddingTop: "2%", paddingBottom: "5%", }}>
                <div className='cart_subject'>
                  <div>
                    <h1 style={{ textAlign: "center", color: "black" }}>CART LIST</h1>
                    <hr />
                  </div>
                  {
                    addToCart.map((item, index) => (
                      <div key={index} style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "5px", padding: "15px", backgroundColor: "#c8c4c4", borderRadius: "8px", position: "relative" }}>
                        <div style={{ position: "relative" }}>
                          <img src={item?.image} height="120px" width="120px" alt='error' />
                        </div>
                        <div>
                          <h5 style={{ margin: 0, padding: 0 }}>{item?.name}</h5>
                          <p style={{ margin: 0, padding: 0 }}>QTY: {item?.quantity}</p>
                          <p style={{ margin: 0, padding: 0 }}>SIZE: {item?.size}</p>
                          <p style={{ margin: 0, padding: 0 }}>PRICE: {item?.price}</p>
                          <p style={{ margin: 0, padding: 0 }}>TOTAL PRICE: {item?.price * item?.quantity}</p>
                        </div>
                        <i className='fas fa-trash' onClick={() => deleteItemCart(index)} style={{ fontSize: '25px', color: "black", marginRight: 8, position: "absolute", top: "0px", left: "0px", cursor: "pointer", backgroundColor: "#ff0000c9", padding: "5px", borderRadius: "4px" }}></i>
                      </div>
                    ))
                  }
                  <div>
                    <button className='btn btn-info' onClick={() => navigate('/product-form')}>Next</button>
                  </div>
                </div>
              </div>
              : ""
          }
        </div>
      </CartContext.Provider>
    </>
  )
}

export default Shopnow