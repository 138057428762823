import { useState } from 'react'
import './Header.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Header = ({ isVisible }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <>
      {/* <header className='header-body d-flex align-item-center justify-content-between position-fixed w-100' style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.3s ease' , zIndex:999}}> */}
      <header className='header-body d-flex align-item-center justify-content-between position-fixed w-100'>
        <div className='logo-image'>
          <img src="./images/premanand_logo_up.png" className='logo' alt="error"></img>
        </div>
        <div className='d-flex align-items-center justify-content-center' >
          <ul id='menu_bar' className={clicked ? "menu_bar" : 'no_menu_bar'}>
            <li className='d-flex align-items-center'><i className="fas fa-home mx-2 headerIconic" style={{ color: "#FAE3B9" }}></i><a href="/" >home</a></li>
            <li className='d-flex align-items-center'><i className="fas fa-info-circle mx-2 headerIconic" style={{ color: "#FAE3B9" }}></i><a href="about">about</a></li>
            <li className='d-flex align-items-center'><i className="fas fa-blog mx-2 headerIconic" style={{ color: "#FAE3B9" }}></i><a href="blog">Blog</a></li>
            {/* <li className='d-flex align-items-center'><i className="fas fa-envelope mx-2 headerIconic" style={{ color: "#FAE3B9" }}></i><a href="shop">Shop now</a></li> */}
            <li className='d-flex align-items-center'><i className="fas fa-envelope mx-2 headerIconic" style={{ color: "#FAE3B9" }}></i><a href="shop">Shop now</a></li>
            <li className='d-flex align-items-center'><i className="fas fa-envelope mx-2 headerIconic" style={{ color: "#FAE3B9" }}></i><a href="contact">Contact</a></li>
            
            {clicked ?
              <div style={{ display: "grid", justifyContent: "center", }}>
                <div style={{ display: "flex", justifyContent: "center", borderBottom:"1px solid white" }}>
                  <a href="https://www.facebook.com/premanandfood" style={{ textDecoration: "none" }} target='blank' className='p-2'><i id="social_media" className='fab fa-facebook' style={{ fontSize: '30px', color: "#FAE3B9" }}></i></a>
                  <a href="https://www.instagram.com/premanandfoods?igsh=MXp6d3NwdWV1N3Nj" target='blank' className='p-2'><i id="social_media" className='fab fa-instagram' style={{ fontSize: '30px', color: "#FAE3B9" }}></i></a>
                  <a href="" className='p-2'><i id="social_media" className='fab fa-linkedin' target='blank' style={{ fontSize: '30px', color: "#FAE3B9" }}></i></a>
                </div>
                <p className='text-center' style={{color:"#FAE3B9", fontSize:"15px", fontWeight:"400"}}>Premanand Food offers the best quality products.</p>
              </div>
              :
              <p></p>
            }
          </ul>


        </div>
        <div className='icon_show' >
          <i className={clicked ? 'fas fa-times' : 'fas fa-bars'} style={{ fontSize: 30, color: "white" }} onClick={(e) => { setClicked(!clicked) }}></i>
        </div>
      </header>
    </>
  )
}

export default Header