import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './Whatsapp.css'

const WhatsAppIcon = () => {
    const handleWhatsAppClick = () => {
        window.open('https://api.whatsapp.com/send?phone=9891466645', '_blank');
    };

    return (
        <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
            <FaWhatsapp size={35} />
        </div>
    );
};

export default WhatsAppIcon;
