import React,{createContext} from 'react'
const CartContext = createContext();
// const context = () => {
//   return (
//     // <CartContext.Provider value={{ addToCart }}>

//     // </CartContext.Provider>
//     <></>
//   )
// }

export default CartContext